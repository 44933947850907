// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
import Zoom from "react-reveal/Zoom"
import MainSlider from "../../components/common/main-slider"
import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline"

import textile_white from "../../images/icons/textile_white.svg"
import intergrate_white from "../../images/icons/intergrate_white.svg"
import advance_white from "../../images/icons/advance_white.svg"

import textile_mint from "../../images/icons/textile_mint.svg"
import intergrate_mint from "../../images/icons/intergrate_mint.svg"
import advance_mint from "../../images/icons/advance_mint.svg"

//import svgs for how it works icons
import intergration_svg from "../../images/visibility/How-it-works/Icons/Integration.svg"
import led_svg from "../../images/visibility/How-it-works/Icons/LED.svg"
import module_svg from "../../images/visibility/How-it-works/Icons/Module.svg"
import pathways_svg from "../../images/visibility/How-it-works/Icons/pathways.svg"

import Interconnects from "../../images/sensing/how-it-works/icons/Interconnects.svg"
import Parent_child from "../../images/sensing/how-it-works/icons/Parent_child.svg"
import Pathways from "../../images/sensing/how-it-works/icons/Pathways.svg"
import Technique from "../../images/sensing/how-it-works/icons/Technique.svg"

//import svgs for platform feature
import collection_salmon from "../../images/sensing/icons/collection_salmon.svg"
import collection_white from "../../images/sensing/icons/collection_white.svg"
import low_salmon from "../../images/sensing/icons/low_salmon.svg"
import low_white from "../../images/sensing/icons/low_white.svg"
import next_salmon from "../../images/sensing/icons/next_salmon.svg"
import next_white from "../../images/sensing/icons/next_white.svg"
import machine_salmon from "../../images/sensing/icons/machine_salmon.svg"
import machine_white from "../../images/sensing/icons/machine_white.svg"

const Sensing = ({ data }) => {
  const images = {
    salmon_texture: convertToBgImage(getImage(data.salmon_texture)),
    hero: convertToBgImage(getImage(data.hero)),
    baner_odlo: convertToBgImage(getImage(data.baner_odlo)),

    //application images
    consumer_img_1: convertToBgImage(getImage(data.app1)),
    consumer_img_2: convertToBgImage(getImage(data.app2)),
    health_img: convertToBgImage(getImage(data.app3)),
    performance_img: convertToBgImage(getImage(data.app4)),
    workwear_img: convertToBgImage(getImage(data.app5)),
    tech_img: convertToBgImage(getImage(data.app6)),

    //how it works images

    Interconnections: convertToBgImage(getImage(data.Interconnections)),
    Parent_child: convertToBgImage(getImage(data.Parent_child)),
    Pathways: convertToBgImage(getImage(data.Pathways)),
    Technique: convertToBgImage(getImage(data.Technique)),

    //capability images
    configure_img: convertToBgImage(getImage(data.capability_img1)),
    research_img: convertToBgImage(getImage(data.capability_img2)),
    tech_spec_img: convertToBgImage(getImage(data.capability_img3)),

    //intergrated carousel images

    visiblity_img: convertToBgImage(getImage(data.inter_img1)),
    data_img: convertToBgImage(getImage(data.inter_img2)),
    feedback_img: convertToBgImage(getImage(data.inter_img3)),
    flexible_battery_img: convertToBgImage(getImage(data.inter_img4)),
    heating_img: convertToBgImage(getImage(data.inter_img5)),
    sensing_img: convertToBgImage(getImage(data.inter_img6)),
    textile_circuitry_img: convertToBgImage(getImage(data.inter_img7)),
    touch_textile_img: convertToBgImage(getImage(data.inter_img8)),

    //advance material images

    accoustic_img: convertToBgImage(getImage(data.advance_img1)),
    bio_mimikry_img: convertToBgImage(getImage(data.advance_img2)),
    memory_foam_img: convertToBgImage(getImage(data.advance_img3)),
    pu_foam_img: convertToBgImage(getImage(data.advance_img4)),
    silicon_print_img: convertToBgImage(getImage(data.advance_img5)),
    silicon_img: convertToBgImage(getImage(data.advance_img6)),

    // textile engineering images

    engineered_knit_img: convertToBgImage(getImage(data.textile_img1)),
    inj_mold_img: convertToBgImage(getImage(data.textile_img2)),
    narrow_width_img: convertToBgImage(getImage(data.textile_img3)),
  }

  const [activePlatform, setActivePlatform] = useState(0)
  const [activePlatform2, setActivePlatform2] = useState(0)
  const [active, setActive] = useState(0)

  const slider = [
    {
      title: "Engineered Knit",
      content:
        "Leveraging Flat, Circular, and Warp knitting techniques to create complex structures, designs, and modulus.",
      image: images.engineered_knit_img,
      link: "/textile-engineering",
    },
    {
      title: "Narrow Width Fabrics",
      content:
        "Elasticated fabrics and elastomeric yarns engineered to achieve stretch and durability, as well as support complex design and construction.",
      image: images.narrow_width_img,
      link: "/textile-engineering",
    },
    {
      title: "Injection Molding",
      content:
        "A revolutionary form and padding technology for impact protection and the encasing of electronics.",
      image: images.inj_mold_img,
      link: "/textile-engineering",
    },
  ]

  const slider2 = [
    {
      title: "Visibility ",
      content:
        " Next-to-skin LED-based lighting that provides on-demand illumination ensuring visibility and safety, now with RGB capabilities.",
      image: images.visiblity_img,
      link: "/integrated-technologies",
    },
    {
      title: "Thermal Regulation",
      content:
        "Intelligent fabric-based thermal regulation, that can support a variety of functions ranging from microclimate regulation to pain management.",
      image: images.heating_img,
      link: "/integrated-technologies",
    },
    {
      title: "Sensing",
      content:
        "Seamlessly integrated biopotential and biomechanical sensors that allow for the collection of metrics ranging from heart rate to motion.",
      image: images.sensing_img,
      link: "/integrated-technologies",
    },
    {
      title: "Feedback",
      content:
        " Haptics, TENS, and EMS integrated into soft goods for tactile feedback, notification, and chronic pain management.",
      image: images.feedback_img,
      link: "/integrated-technologies",
    },
    {
      title: "Touch Textiles",
      content:
        " Pressure-sensitive textile constructions used to create interactive interfaces, replacing the need for traditional plastic components.",
      image: images.touch_textile_img,
      link: "/integrated-technologies",
    },
    {
      title: "Data Transfer",
      content:
        " Transfer of data and power across fabric substrates with limited flow and interference and low resistance, supporting over 4gbps and 40V at 5A.",
      image: images.data_img,
      link: "/integrated-technologies",
    },
    {
      title: "Flexible Battery",
      content:
        " Adaptable battery for a multitude of wearable uses. Flexible, washable, semi-solid electrolyte construction that serves as the industry’s solution to the growing design needs.",
      image: images.flexible_battery_img,
      link: "/integrated-technologies",
    },

    {
      title: "Textile Circuitry",
      content:
        " A collection of conductive pathways and inlays, interconnects and encapsulation to present technology in a seamless and invisible manner",
      image: images.textile_circuitry_img,
      link: "/integrated-technologies",
    },
  ]

  const slider3 = [
    {
      title: "Bio-mimicking material",
      content:
        "Thermo-responsive polymers and yarns, pine-cone biomimetic yarn for moisture management for improved comfort over extended wear times.",
      image: images.bio_mimikry_img,
      link: "/advanced-materials",
    },
    {
      title: "PU foam-based Injection Molding",
      content:
        "Replacing hard plastic components for flexibility, durability, and seamless integration of sensors",
      image: images.pu_foam_img,
      link: "/advanced-materials",
    },
    {
      title: "Acoustic dampening fabrics",
      content:
        "Certified Acoustic fabrics specifically designed for uninterrupted sound passthrough from materials to product.",
      image: images.accoustic_img,
      link: "/advanced-materials",
    },
    {
      title: "Memory Foam with PU Leather",
      content:
        "Internal and external fabric/leather lamination on foam provides superior comfort and flexibility. Memory foam built for high impact/low frequency.",
      image: images.memory_foam_img,
      link: "/advanced-materials",
    },

    {
      title: "Silicone Printing and Embellishments",
      content:
        "Patented Silicone Application Technology to enable targeted modification of fabric modulus using engineered functional prints for grip and comfort",
      image: images.silicon_print_img,
      link: "/advanced-materials",
    },
  ]

  const howItWorks = [
    {
      title: "Parent-Child",
      content: "Parent-child circuitry for wider, more customisable coverage.",
      image: Parent_child,
      cover: images.Parent_child,
    },
    {
      title: "Technique",
      content: "Seamless sensor-to-textile attachment options.",
      image: Technique,
      cover: images.Technique,
    },
    {
      title: "Pathways",
      content: "Conductive pathways strategically placed for optimal comfort.",
      image: Pathways,
      cover: images.Pathways,
    },
    {
      title: "Interconnections",
      content: "A variety of integratable interconnection systems.",
      image: Interconnects,
      cover: images.Interconnections,
    },
  ]

  function hello() {
    console.log(howItWorks[active])
  }

  const platform = [
    {
      title: "Technology Specification",
      content: "Thermal sensor used in thermo-regulaiton platform",
      content2: "EMG/ECG sensors used in sports performance applications.",
      content3: "EEG and ECG used in multiple healthcare application.",
      image: "../../images/brand/symbol-mint.png",
      cover: images.tech_spec_img,
    },
    {
      title: "Configurations",
      content:
        "Portfolio of knitted, woven, printed and silicone extruded sensors",
      content2: "Miniaturized, seamlessly integratable onto fabrics",
      content3:
        "Tracking metrics ranging from ECG, EEG, EMG, Movement, Temperature, Pressure",
      content4: "Range of packaging based on final textile configuration",
      image: "../../images/brand/symbol-mint.png",
      cover: images.configure_img,
    },
    {
      title: "Research",
      content:
        "Collaborative research carried out by the Loughborough University, focused around the factors affecting motion, technique and post-activity feedback analysis.",
      content2: "",
      content3: "",
      image: "../../images/brand/symbol-mint.png",
      cover: images.research_img,
    },
  ]

  const platformSlider = [
    {
      title: "Textile Engineering",
      image: textile_white,
      image_active: textile_mint,
    },
    {
      title: "Integrated Technologies",
      image: intergrate_white,
      image_active: intergrate_mint,
    },
    {
      title: "Advanced Material",
      image: advance_white,
      image_active: advance_mint,
    },
  ]

  const platformFeature = [
    {
      title: "Collection",
      content: "A portfolio of IMU, ECG, EMG and temperature sensors.",
      icon: collection_salmon,
      icon_white: collection_white,
    },
    {
      title: "Machine Washable",
      content: "Machine washable sensor systems.",
      icon: machine_salmon,
      icon_white: machine_white,
    },
    {
      title: "Low Profile",
      content: "Lightweight components used",
      icon: low_salmon,
      icon_white: low_white,
    },
    {
      title: "Next-to-Skin",
      content: "Skin touching components merged into fabrics as a single unit.",
      icon: next_salmon,
      icon_white: next_white,
    },
  ]

  return (
    <Layout>
      <Seo title="Sensing" />
      <section>
        <BackgroundImage {...images.hero} className="h-screen bg-dusk">
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col ">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center  mb-1 text-white">
                    Soft Sensing
                  </div>
                  <div className="sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    An unmatched sensing offering coupled with next-to-skin
                    softness and integration
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="bg-chalk">
          <div className="container mx-auto py-20">
            <div className="flex flex-col items-center">
              <Fade bottom cascade>
                <div>
                  <div className="text-salmon mb-4 uppercase text-center ">
                    UNDERSTANDING HUMAN POTENTIAL
                  </div>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Next-To-Skin Sensing
                  </div>
                  <div className="text-2xl font-light text-center max-w-5xl mx-auto leading-snug text-dusk">
                    Capturing health and motion-related information through the
                    integration of textile-based and electronic sensors into
                    apparel and soft goods – a truly untethered and flexible
                    approach to data gathering.
                  </div>
                  <div className="text-2xl font-light text-center max-w-5xl mx-auto leading-snug mt-4 text-dusk">
                    The advancements in our integration techniques have given us
                    the ability to integrate our sensors into a wide variety of
                    form factors – allowing us to customize and optimize our
                    offerings to create the optimal end product.
                  </div>
                </div>
              </Fade>
              <div className="mt-24">
                <Fade bottom>
                  <div className="text-4xl text-center font-light mb-4 text-dusk">
                    Platform Features
                  </div>
                </Fade>
                <Fade bottom cascade>
                  <div className="grid md:grid-cols-2 grid-cols-1 lg:grid-cols-4 gap-4 mt-8 text-dusk sm:text-left text-center">
                    {platformFeature.map((item, index) => {
                      return (
                        <div
                          className="border border-salmon p-5 pb-10 group hover:bg-salmon duration-200 hover:text-white group"
                          key={index}
                        >
                          <div className="flex sm:justify-start justify-center">
                            <img
                              src={item.icon}
                              alt="Softmatter"
                              width={80}
                              className="group-hover:hidden block"
                            />
                            <img
                              src={item.icon_white}
                              alt="Softmatter"
                              width={80}
                              className="group-hover:block hidden"
                            />
                          </div>
                          <div className="text-xl font-light font-firs-regular border-b border-salmon text-dusk group-hover:text-white duration-200 group-hover:border-chalk pb-4 max-w-5xl leading-snug mt-4">
                            {item.title}
                          </div>
                          <div className="text-base font-light max-w-5xl leading-snug mt-4">
                            {item.content}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Fade>
              </div>
            </div>
            <div className="mt-24">
              <Fade bottom cascade>
                <div>
                  <div className="text-4xl font-light text-dusk mb-4 sm:text-left text-center">
                    How it Works
                  </div>
                  <div className="text-2xl font-light text-dusk max-w-4xl sm:text-left text-center leading-snug">
                    Expert craftsmanship over the years has allowed us to
                    pioneer and become masters of non-obtrusive sensor
                    integration in apparel and soft goods.
                  </div>
                </div>
              </Fade>
              <div className="mt-14">
                <div className="flex flex-wrap items-center justify-center lg:gap-10 gap-5">
                  {howItWorks.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div>
                          <div
                            className={
                              active == index
                                ? "font-firs-semibold cursor-pointer text-salmon sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-salmon duration-200"
                                : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-transparent duration-200"
                            }
                            onClick={() => {
                              setActive(index)
                              hello()
                            }}
                          >
                            {item.title}
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
                <div className="mt-8">
                  <Fade bottom>
                    <div className="flex lg:flex-row flex-col sm:text-left text-center">
                      <BackgroundImage
                        {...howItWorks[active].cover}
                        className="w-full lg:w-2/5 py-44"
                      ></BackgroundImage>
                      <div className="w-full lg:w-3/5 bg-dusk p-14 flex flex-col justify-center">
                        <div className="flex sm:justify-start justify-center">
                          <img
                            src={howItWorks[active].image}
                            width={80}
                            alt="Softmatter"
                            className="cursor-pointer"
                          />
                        </div>
                        <div className="text-2xl font-light mt-8 text-white">
                          {howItWorks[active].title}
                        </div>
                        <div className="text-xl font-light mt-2 text-white max-w-lg">
                          {howItWorks[active].content}
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-salmon overflow-hidden">
          <div className="flex lg:flex-row flex-col">
            <div className="w-full lg:w-1/2 lg:hidden block">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.consumer_img_1}
                    className="xl:py-80 md:py-96 py-52"
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
            <div className="lg:w-1/2 w-full py-20 lg:py-0 pl-5 sm:pl-20 xl:pl-36 flex flex-col justify-center">
              <Fade bottom cascade>
                <div className="text-center sm:text-left pr-5 sm:pr-10 xl:pr-20">
                  <div className="text-4xl font-light mb-4  text-dusk ">
                    Listening to your body
                  </div>
                  <div className="text-xl font-light max-w-5xl leading-snug text-white">
                    With a focus on health and wellness in today’s society, it
                    is of paramount importance that we understand how we treat
                    our bodies, both at rest and during intense activity.
                    Through market research, we learned that any solution that
                    seeks to provide this understanding needs to be sleek,
                    lightweight, and most importantly, comfortable. As such, we
                    have developed a range of low-profile sensors that can be
                    seamlessly integrated into apparel and soft goods – allowing
                    us to be conscious of our health, obtain real-time data, and
                    optimize our performance.
                  </div>
                  {/* <div className="text-xl font-light max-w-5xl leading-snug mt-4 text-white">
                    As this technology has matured, it has seen increased use in
                    thermotherapy wearables targeted at pain relief and
                    soreness.
                  </div> */}
                </div>
              </Fade>
            </div>
            <div className="w-full lg:w-1/2 lg:block hidden">
              <Fade right>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.consumer_img_1}
                    className="py-80 "
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
          </div>
          <div className="flex lg:flex-row flex-col">
            <div className="w-full lg:w-1/2">
              <Fade left>
                <div className="w-full ">
                  <BackgroundImage
                    {...images.consumer_img_2}
                    className="xl:py-80 md:py-96 py-52"
                  ></BackgroundImage>
                </div>
              </Fade>
            </div>
            <div className="lg:w-1/2 w-full py-20 lg:py-0 pl-5 sm:pl-20 xl:pl-36 flex flex-col justify-center">
              <Fade bottom cascade>
                <div className="text-center sm:text-left pr-5 sm:pr-10 xl:pr-20">
                  <div className="text-4xl font-light mb-4 text-dusk ">
                    Challenge accepted!
                  </div>
                  <div className="text-xl font-light max-w-5xl leading-snug text-white">
                    In collaboration with Loughborough University, we conducted
                    studies that dove into biomechanics and form, fabric sensor
                    creation, as well as the non-obtrusive integration of fabric
                    sensors into apparel and soft goods. This exercise led to us
                    creating sensors that are either fully encapsulated or
                    water-resistant, and thus washing machine friendly. The
                    advancements in our integration techniques have given us the
                    ability to integrate our sensors into a wide variety of form
                    factors – allowing us to customize our and optimize our
                    offerings to create the optimal end product.
                  </div>
                  {/* <div className="text-xl font-light max-w-5xl leading-snug mt-4 text-white">
                    As this technology has matured, it has seen increased use in
                    thermotherapy wearables targeted at pain relief and
                    soreness.
                  </div> */}
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk">
          <div className=" pt-24 px-5">
            <Fade bottom cascade>
              <div className="text-white">
                <div className="text-center text-2xl font-firs-thin uppercase mb-4">
                  Our Sensing
                </div>
                <div className="text-center text-5xl md:text-6xl mb-4">
                  Applications
                </div>
                <div className="text-2xl mt-2 text-center max-w-4xl mx-auto">
                  Being able to capture metrics ranging from heart rate to
                  motion, to skin temperature, we have created sensing products
                  for end consumers ranging from athletes to designers.
                </div>
              </div>
            </Fade>
            <div className="mt-32 overflow-hidden">
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:hidden block">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.health_img}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Healthcare
                      </div>
                      <div className="text-base max-w-xl">
                        Remote patient monitoring solutions focused on the
                        real-time collection and transmission of ECG, motion and
                        temperature derived metrics.
                        <br />
                        + Remote patient monitoring garments and accessories
                        <br />+ Technology distributed to straps of smart
                        watches and harnesses
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-1/2 lg:block hidden">
                  <Fade right>
                    <div className="w-full ">
                      <BackgroundImage
                        {...images.health_img}
                        className="py-60 "
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:w-1/2">
                  <Fade left>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.tech_img}
                        className="py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Consumer technology
                      </div>
                      <div className="text-base max-w-xl">
                        From smart soft-good solutions designed around immersive
                        and responsive experiences for AR/VR, to the creation of
                        intelligently actuating wearables based on sensor
                        tracked stimuli
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="flex lg:flex-row flex-col ">
                <div className="w-full lg:hidden block">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.workwear_img}
                        className="py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
                <div className="lg:w-1/2 w-full lg:pl-36 pl-10 flex flex-col justify-center">
                  <Fade>
                    <div className="text-white pr-10 sm:text-left text-center lg:py-0 py-10">
                      <div className="text-4xl font-light mb-3 leading-tight">
                        Workwear
                      </div>
                      <div className="text-base max-w-xl">
                        Posture and muscle strain monitoring for actively
                        engaged employees to provide corrective feedback in
                        situations of bad form or technique, for greater
                        occupational safety.
                      </div>
                      <Link to="/contact">
                        <button className="uppercase px-4 py-3 border border-salmon rounded-full text-salmon mt-6 hover:bg-salmon/20 cursor-pointer duration-200">
                          Request Information
                        </button>
                      </Link>
                    </div>
                  </Fade>
                </div>
                <div className="w-1/2 lg:block hidden">
                  <Fade right>
                    <div className="w-full">
                      <BackgroundImage
                        {...images.workwear_img}
                        className="py-60"
                      ></BackgroundImage>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <BackgroundImage {...images.baner_odlo} className=" p-5 sm:p-24">
        <div className="bg-salmon max-w-lg p-12 text-center sm:text-left">
          <div className="text-4xl font-light mb-3 leading-tight">
            Our Sensor Collection
          </div>
          <div className="text-2xl font-firs-regular max-w-xl text-white mb-5">
            Fabric Sensors
            <div className="text-xl font-firs-light max-w-xl text-white mb-2">
              Knitted
            </div>
            <div className="text-xl font-firs-light max-w-xl text-white">
              Woven
            </div>
          </div>
          <div className="text-2xl font-firs-regular max-w-xl text-white mb-5">
            Printed Sensors
            <div className="text-xl font-firs-light max-w-xl text-white mb-2">
              Conductive Ink
            </div>
            <div className="text-xl font-firs-light max-w-xl text-white">
              Polymers
            </div>
          </div>
          <div className="text-2xl font-firs-regular max-w-xl text-white mb-5">
            Chemical Sensors
          </div>
          <div className="text-2xl font-firs-regular max-w-xl text-white mb-5">
            IMU
          </div>
          <div className="text-2xl font-firs-regular max-w-xl text-white">
            Silicone Extruded (In development)
          </div>
          {/* <StaticImage
            alt="Softmatter"
            src="../../images/thermal-regulation/logo-odlo.png"
            width={180}
          /> */}
        </div>
      </BackgroundImage>
      <section>
        <div className="py-20 bg-chalk ">
          <div className="container mx-auto">
            <Fade top>
              <div className="text-4xl font-light text-center text-dusk mb-4">
                Platform Capabilities
              </div>
            </Fade>
            <Fade bottom>
              <div className="text-2xl font-light text-center mx-auto text-dusk max-w-4xl leading-snug">
                An unmatched sensing offering coupled with next-to-skin softness
                and integration
              </div>
            </Fade>
            <div className="mt-14">
              <div className="flex items-center justify-center lg:gap-10 gap-5 flex-wrap">
                {platform.map((item, index) => {
                  return (
                    <Fade bottom cascade key={index}>
                      <div>
                        <div
                          className={
                            activePlatform2 == index
                              ? "font-firs-semibold cursor-pointer text-salmon sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-salmon duration-200"
                              : "font-firs-semibold cursor-pointer text-dusk sm:text-xl text-base sm:text-left text-center pb-2 border-b-2 px-3 border-transparent duration-200"
                          }
                          onClick={() => {
                            setActivePlatform2(index)
                            hello()
                          }}
                        >
                          {item.title}
                        </div>
                      </div>
                    </Fade>
                  )
                })}
              </div>
              <div className="mt-8">
                <Fade bottom>
                  <div className="flex lg:flex-row flex-col sm:text-left text-center">
                    <BackgroundImage
                      {...platform[activePlatform2].cover}
                      className="w-full lg:w-2/5 py-44"
                    ></BackgroundImage>
                    <div className="w-full lg:w-3/5 bg-dusk p-10 sm:p-14 flex flex-col justify-center">
                      <div className="text-2xl font-light text-white">
                        {platform[activePlatform2].title}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content2}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content3}
                      </div>
                      <div className="text-base font-light mt-4 text-white max-w-2xl">
                        {platform[activePlatform2].content4}
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dusk py-24 overflow-hidden relative">
          <div className="container mx-auto">
            <div className="mb-20">
              <Fade top>
                <div className="text-center text-2xl font-firs-thin text-white mb-4">
                  TECHNOLOGY WOVEN IN
                </div>
              </Fade>
              <Fade bottom>
                <div className="text-center text-4xl text-white">
                  Explore All Platforms
                </div>
              </Fade>
            </div>
            <div className="flex lg:flex-row flex-col mb-72 lg:mb-32">
              <div className="w-full lg:w-4/12">
                <div className="flex gap-5 lg:gap-10 lg:flex-col justify-center flex-row">
                  {platformSlider.map((item, index) => {
                    return (
                      <Fade bottom cascade key={index}>
                        <div className=" text-white mb-14">
                          <div className="uppercase text-xl lg:block flex justify-center font-light mb-4">
                            {activePlatform == index ? (
                              <>
                                <img
                                  src={item.image_active}
                                  width={60}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            ) : (
                              <>
                                <img
                                  src={item.image}
                                  width={60}
                                  alt="Softmatter"
                                  className="cursor-pointer sm:w-16 w-10"
                                  onClick={() => setActivePlatform(index)}
                                />
                              </>
                            )}
                          </div>
                          <div
                            className={
                              activePlatform == index
                                ? "mt-6  text-xs sm:text-xl text max-w-sm text-mint duration-100  lg:text-left text-center"
                                : "mt-6  text-xs sm:text-xl text max-w-sm duration-100 lg:text-left text-center"
                            }
                          >
                            <span
                              className={
                                activePlatform == index
                                  ? "pb-2 border-b-0 lg:border-b-2 border-mint duration-100 cursor-pointer"
                                  : "pb-2 duration-100 cursor-pointer hover:border-b-2 border-white"
                              }
                              onClick={() => setActivePlatform(index)}
                            >
                              {item.title}
                            </span>
                          </div>
                        </div>
                      </Fade>
                    )
                  })}
                </div>
              </div>
              <div className="w-full lg:w-7/12 pb-96 mb-32 sm:mb-24 lg:mb-0 lg:pb-24">
                <div className="text-white absolute w-full">
                  {activePlatform == 0 && (
                    <>
                      <MainSlider slider_array={slider} hover />
                    </>
                  )}
                  {activePlatform == 1 && (
                    <>
                      <MainSlider slider_array={slider2} hover />
                    </>
                  )}
                  {activePlatform == 2 && (
                    <>
                      <MainSlider slider_array={slider3} hover />
                    </>
                  )}

                  <div className="flex pr-0 sm:pr-20 lg:pr-96 mr-0 sm:mr-20 md:mr-60 xl:mr-80 2xl:mr-96 justify-center items-center mt-14">
                    <div className="text-white my-custom-pagination-div ml-0 xl:ml-16 2xl:ml-24"></div>
                    <div className="flex justify-center mr-20">
                      <div className="swiper-button-prev-square py-3 px-4 cursor-pointer flex items-center justify-center bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronLeftIcon width={20} />
                      </div>
                      <div className="swiper-button-next-square py-3 px-4 cursor-pointer bg-black/50 text-white hover:bg-white hover:text-dusk duration-200">
                        <ChevronRightIcon width={20} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="flex lg:flex-row flex-col">
          <div className="w-full lg:w-1/2">
            <BackgroundImage
              {...images.salmon_texture}
              className="h-full bg-cover lg:py-0 py-40"
            >
              <Zoom>
                <div className="flex h-full items-center justify-center">
                  <StaticImage
                    src="../images/brand/logo-symbol.png"
                    placeholder="blurred"
                    alt="Softmatter"
                    width={180}
                    className="lg:w-auto w-20"
                  />
                </div>
              </Zoom>
            </BackgroundImage>
          </div>
          <div className="w-full lg:w-1/2">
            <Fade bottom cascade>
              <div className="bg-chalk py-20 lg:py-56 text-dusk px-10">
                <div className="text-center  text-5xl sm:text-6xl mb-4">
                  How to Engage
                </div>
                <div className="text-xl mt-2 text-center max-w-xl mx-auto">
                  At Softmatter, our dynamic team has industry-leading expertise
                  in smart textiles, from design to manufacture. We help you
                  strategically enhance your offering so that it has a profound,
                  global impact.
                </div>
                <div className="text-2xl mt-5 text-center max-w-4xl mx-auto">
                  Interested in enabling your idea?
                </div>
                <div className="flex justify-center mt-8">
                  <Link to="/contact">
                    <button className="px-8 py-4 bg-salmon  hover:bg-salmon/90 duration-200 text-white text-2xl">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query SensingImages {
    salmon_texture: file(relativePath: { eq: "textures/salmon_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    hero: file(relativePath: { eq: "sensing/hero.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    baner_odlo: file(relativePath: { eq: "sensing/SensorUpdate.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    patented_awarded: file(
      relativePath: { eq: "visibility/Patentetd_awarded.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app1: file(relativePath: { eq: "sensing/Listening.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app2: file(relativePath: { eq: "sensing/Challenge.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app3: file(relativePath: { eq: "sensing/applications/healthcare.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app4: file(
      relativePath: { eq: "sensing/applications/Performance_outdoor.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app5: file(relativePath: { eq: "sensing/applications/workwear.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    app6: file(relativePath: { eq: "sensing/applications/Tech.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    howitworks_1: file(
      relativePath: { eq: "visibility/How-it-works/Integration.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    Parent_child: file(
      relativePath: { eq: "sensing/how-it-works/Parent_child.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    Pathways: file(relativePath: { eq: "sensing/how-it-works/Pathways.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Interconnections: file(
      relativePath: { eq: "sensing/how-it-works/Interconnections.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    Technique: file(
      relativePath: { eq: "sensing/how-it-works/Technique.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    capability_img1: file(
      relativePath: { eq: "sensing/platformCapabilities/Configurations_1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    capability_img2: file(
      relativePath: { eq: "sensing/platformCapabilities/research.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    capability_img3: file(
      relativePath: { eq: "sensing/platformCapabilities/Technology_specs.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img1: file(
      relativePath: { eq: "platform-carousel/Integrated/Visibility.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img2: file(
      relativePath: { eq: "platform-carousel/Integrated/Data.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img3: file(
      relativePath: { eq: "platform-carousel/Integrated/Feedback.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img4: file(
      relativePath: { eq: "platform-carousel/Integrated/Flexible_Battery.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img5: file(
      relativePath: { eq: "platform-carousel/Integrated/Heating.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img6: file(
      relativePath: { eq: "platform-carousel/Integrated/Sensing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img7: file(
      relativePath: { eq: "platform-carousel/Integrated/Textile_circuitry.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    inter_img8: file(
      relativePath: { eq: "platform-carousel/Integrated/Touch_textiles.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img1: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Accoustic.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img2: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Bio_Mimikry_Material.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img3: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Memory_Foam_with_PU_Leather.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img4: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/PU_foam_based_Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img5: file(
      relativePath: {
        eq: "platform-carousel/Advanced-Materials/Silicone_Printing.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    advance_img6: file(
      relativePath: { eq: "platform-carousel/Advanced-Materials/Silicone.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel1: file(
      relativePath: { eq: "Main_category_carousel/Advanced-Material.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel2: file(
      relativePath: { eq: "Main_category_carousel/Integrated.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    main_carousel3: file(
      relativePath: { eq: "Main_category_carousel/Textile-Engineering.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img1: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Engineered_Knit.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img2: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Injection_Molding.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }

    textile_img3: file(
      relativePath: {
        eq: "platform-carousel/Textile-Engineering/Nawrrow_Width.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default Sensing
